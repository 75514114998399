<template>
  <div>
    <Header :seet="number" />
      <el-row type="flex" class="account—settings">
        <el-col :span="3" style="width:15.3%;"></el-col>
        <el-col :span="17">
          <div class="settings_box">
            <el-breadcrumb separator-class="el-icon-arrow-right">
              <el-breadcrumb-item>账号中心</el-breadcrumb-item>
              <el-breadcrumb-item @click.native="goto()">收货地址</el-breadcrumb-item>
              <el-breadcrumb-item>编辑地址</el-breadcrumb-item>
            </el-breadcrumb>
            <div style="display:flex;">
              <div class="box_left">
                <LeftHurdle/>
              </div>
              <div class="box_right">
                <div class="box_right_top">编辑地址</div>
                <div class="box_right_cent">
                     <div class="form_box">
                      <el-form :model="ruleForm" :rules="rules" ref="ruleForm" class="demo-ruleForm">
                        <el-form-item label="收货人" prop="userName">
                          <el-input v-model="ruleForm.userName"></el-input>
                        </el-form-item>
                        <el-form-item label="所在地区" prop="selectedOptions">
                          <el-cascader
                            :options="options"
                            ref="cascaderAddr"
                            v-model="ruleForm.selectedOptions"
                            @change="handleChange">
                          </el-cascader>
                        </el-form-item>
                        <el-form-item label="具体地址" prop="company">
                          <el-input v-model="ruleForm.company"></el-input>
                        </el-form-item>
                        <el-form-item label="手机号码" prop="number">
                          <el-input v-model="ruleForm.number"></el-input>
                        </el-form-item>
                        <el-form-item label="设置默认" class="flex-start">
                          <el-switch v-model="ruleForm.delivery"></el-switch>
                        </el-form-item>
                        <el-form-item >
                          <div class="flex-start">
                            <div class="newly_btn" @click="preserve()">保存</div>
                            <div class="newly_Btn" @click="cancel()">取消</div>
                          </div>
                          
                        </el-form-item>
                      </el-form>
                    </div>
                        
                  
                </div>
              </div>
            </div>
            
          </div>
        </el-col>
        <el-col :span="3" style="width:15.5%;"></el-col>
    </el-row>
    <Footer/>
  </div>
</template>

<script>
import Header from '@/components/Layout/header.vue'
import Footer from '@/components/Layout/footer.vue'
import LeftHurdle from '@/components/Layout/leftHurdle.vue'
import { compileAddress } from "@/api/personage/personage";
import { phoneReg } from '@/utils/regular'
let pcas = require('@/assets/json/pca-code.json')
export default {
  components: { Header,Footer,LeftHurdle },
  data () {
      return{
        number:'4',
        options:pcas,
        ruleForm: {
          userName:'',
          number:'',
          company:'',
          delivery:false,
          selectedOptions:[],
          id:''

        },
        rules: {
          userName: [
            { required: true, message: '请输入收货人', trigger: 'blur' },
          ],
          selectedOptions: [
            { required: true, message: '请输入所在地址', trigger: 'blur' },
          ],
          number: [
            { required: true, message: '请输入手机号', trigger: 'blur' },
            { pattern: phoneReg, message: "请输入正确的手机号码"}
          ],
          company: [
            { required: true, message: '请输入详细地址', trigger: 'blur' },
          ],
        }
      }
  },
  created(){
    let addressList = JSON.parse(this.$route.query.data)
    this.ruleForm.userName=addressList.receiveName
    this.ruleForm.number=addressList.receivePhone
    let obj =[addressList.province,addressList.city,addressList.area]
    let arr =pcas
    let data =[]
    obj.forEach((item)=>{
      let tempArr = arr.filter((items) => items.label == item);
      if (tempArr.length != 0) {
          data.push(tempArr[0].value) ;
      }
      arr = tempArr.length != 0 ? tempArr[0].children : [];
    })
    this.ruleForm.selectedOptions = data
    this.ruleForm.company=addressList.address
    this.id=addressList.id
    if(addressList.isDefault===1){
      this.ruleForm.delivery=true
    }
  },
  methods:{
    //地址选择
    handleChange(e, form, thsAreaCode) {
        thsAreaCode = this.$refs['cascaderAddr'].getCheckedNodes()[0].pathLabels// 注意2： 获取label值
        console.log(thsAreaCode) // 注意3： 最终结果是个一维数组对象
    },
    preserve(){
      this.$refs['ruleForm'].validate((valid) => {
        if(valid){
          let thsAreaCode=this.$refs['cascaderAddr'].getCheckedNodes()[0].pathLabels
          if(this.ruleForm.delivery==false){
            this.isDefault=0
          }else{
            this.isDefault=1
          }
          let data={
            "address": this.ruleForm.company,
            "addressId": parseInt(this.id),
            "area": thsAreaCode[2],
            "city": thsAreaCode[1],
            "isDefault": this.isDefault,
            "labelName": "",
            "province": thsAreaCode[0],
            "receiveName": this.ruleForm.userName,
            "receivePhone": this.ruleForm.number
          }
          compileAddress(data).then((data)=>{
            if(data.code ==='00000'){
              this.$message({
                message: "保存成功",
                type: "success",
              });
              this.$router.push({ name: 'ShippAddress' })
            }
          })
        }
      })
    },
    cancel(){
      this.$router.push({ name: 'ShippAddress' })
    },
    goto(){
      this.$router.push({ name: 'ShippAddress'})
    }
  }

}
</script>

<style lang="scss">
.settings_box{
  margin: 30px 0;
  .el-breadcrumb{
    font-size: 18px;
    padding-bottom: 20px;
  }
  .box_left{
    margin-right: 20px;
  }
  .box_right{
    width: 87%;
    height: 660px;
    border: 1px solid #d7d7d7;
    border-radius: 10px;
    .box_right_top{
      height: 40px;
      line-height: 40px;
      padding-left: 40px;
      background: #d7d7d7;
      border-bottom: 1px solid #d7d7d7;
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
      font-weight: bold;
    }
    .form_box{
      padding: 30px;
      .el-form-item__label{
        float: none;
      }
      .el-input{
        width: 50%;
      }
      .el-cascader{
        width: 100%;
      }
      .newly_btn{
        color: #fff;
        background: #CD9F49;
        width: 78px;
        height: 28px;
        line-height: 28px;
        border-radius: 5px;
        text-align: center;
      }
      .newly_Btn{
        width: 78px;
        height: 28px;
        line-height: 28px;
        border-radius: 5px;
        text-align: center;
        border: 1px solid #333;
        margin-left: 15px;
      }
    }
  }
}
</style>