import request from '@/utils/request'
// import qs from 'qs'

/**
 * 绑定邮箱
 * @param data
 */
 export function bindEmail(data) {
    return request({
        url: 'admin/admin/base-user/bindEmail',
        method: 'post',
        data
    })
}

/**
 * 绑定手机号
 * @param data
 */
 export function bindPhone(data) {
    return request({
        url: 'admin/admin/base-user/bindPhone',
        method: 'post',
        data
    })
}
/**
 * 新增地址
 * @param data
 */
 export function newAddress(data) {
    return request({
        url: 'admin/admin/bulk-purchase-employee-address/addAddress',
        method: 'post',
        data
    })
}

/**
 * 编辑地址
 * @param data
 */
 export function compileAddress(data) {
    return request({
        url: 'admin/admin/bulk-purchase-employee-address/editAddress',
        method: 'post',
        data
    })
}

/**
 * 导出地址模板
 * @param data
 */
 export function deriveAddress() {
    return request({
        url: 'admin/admin/bulk-purchase-employee-address/downloadModel',
        method: 'get',
        responseType:'blob'
    })
}

/**
 * 导入地址模板
 * @param data
 */
 export function channelAddress(data) {
    return request({
        url: 'admin/admin/bulk-purchase-employee-address/importAddressTemplate',
        method: 'post',
        header: {
            'content-type': 'multipart/form-data'
        },
        data
    })
}
/**
 * 删除地址
 * @param data
 */
 export function DeleteAddress(data) {
    return request({
        url: 'admin/admin/bulk-purchase-employee-address/deleteAddress',
        method: 'post',
        data
    })
}